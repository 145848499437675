import config from '@/utility/config';
import { getAxiosError } from './helpers';
import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';

export const createDiscountCode = async ({ communityId, params }) => {
  try {
    const apiPath = `${config.learnPortalBackend}/api/v1/communities/${communityId}/discounts`;
    const res = await adminProtectedAxiosRequest.post(apiPath, params);
    return { data: res.data, error: false };
  } catch (error) {
    return { error: getAxiosError(error) };
  }
};

export const editDiscountCode = async ({
  communityId,
  discountId,
  params
}) => {
  try {
    const apiPath = `${config.learnPortalBackend}/api/v1/communities/${communityId}/discounts/${discountId}`;
    const res = await adminProtectedAxiosRequest.put(apiPath, params);
    return { data: res.data, error: false };
  } catch (error) {
    return { error: getAxiosError(error) };
  }
};

export const checkDiscountCode = async ({ communityId, params }) => {
  try {
    const apiPath = `${config.learnPortalBackend}/api/v1/communities/${communityId}/discounts/validate`;
    const res = await adminProtectedAxiosRequest.get(apiPath, { params });
    return { data: res.data, error: false };
  } catch (error) {
    return { error: getAxiosError(error) };
  }
};

export const getDiscounts = async ({ communityId, params }) => {
  try {
    const apiPath = `${config.learnPortalBackend}/api/v1/communities/${communityId}/discounts`;
    const res = await adminProtectedAxiosRequest.get(apiPath, { params });
    return { data: res.data, error: false };
  } catch (error) {
    return { error: getAxiosError(error) };
  }
};

export const getDiscountInfo = async ({ communityId, code }) => {
  try {
    const apiPath = `${config.learnPortalBackend}/api/v1/communities/${communityId}/discounts/${code}`;
    const res = await adminProtectedAxiosRequest.get(apiPath);
    return { data: res.data, error: false };
  } catch (error) {
    return { error: getAxiosError(error) };
  }
};
