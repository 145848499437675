import PropTypes from 'prop-types';
import style from './ToolTip.module.scss';
import { usePopper } from 'react-popper';
import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';

const DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

const ToolTip = ({
  children,
  text,
  customClassName,
  customTextClassName,
  customContainerClassName,
  direction,
  isArrowHidden,
  //we have some links in the tooltip should be clickable,
  //so we need to have delay time when we shift from the icon to the tooltip content
  tooltipClosingDelayMs
}) => {
  const className = 'c-ToolTip';

  const isTooltipOnRef = useRef(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isTooltipOn, setIsTooltipOn] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: direction,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10]
          }
        }
      ]
    }
  );

  const contentClassName = [
    style[`${className}__content`],
    style[`${className}__content--${direction}`]
  ].join(' ');

  const arrowClassName = [
    style[`${className}__arrow`],
    style[`${className}__arrow--${direction}`]
  ].join(' ');

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className={`${style[className]} relative ${
        customContainerClassName ?? ''
      }`}
      ref={setReferenceElement}
      onMouseOver={() => {
        setIsTooltipOn(true);
      }}
      onMouseLeave={() => {
        const mouseLeave = () => {
          if (!isTooltipOnRef.current) {
            setIsTooltipOn(false);
          } else {
            isTooltipOnRef.current = false;
          }
        };

        if (tooltipClosingDelayMs) {
          setTimeout(mouseLeave, tooltipClosingDelayMs);
        } else {
          mouseLeave();
        }
      }}>
      {children}
      {isTooltipOn &&
        createPortal(
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <div
            className={`${contentClassName} bg-dark-2c px-12 py-8 rounded-8 ${customClassName}`}
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper}
            onMouseOver={() => {
              setIsTooltipOn(true);
              isTooltipOnRef.current = true;
            }}
            onMouseLeave={() => {
              setIsTooltipOn(false);
            }}>
            {!isArrowHidden && <div className={arrowClassName}></div>}
            <div
              className={`font-normal text-label-md text-white-default ${customTextClassName}`}>
              {typeof text === 'function' ? text() : text}
            </div>
          </div>,
          document.getElementById('tooltip')
        )}
    </div>
  );
};

ToolTip.defaultProps = {
  direction: DIRECTION.BOTTOM,
  customClassName: '',
  customTextClassName: ''
};

ToolTip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.oneOf(Object.values(DIRECTION))
};

export default ToolTip;
