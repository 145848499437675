import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';

import style from './Card.module.scss';

const cardTypes = {
  light: 'light',
  dark: 'dark'
};

const cardTypesArr = Object.keys(cardTypes);

const Card = ({ success, error, center, type, children }) => {
  const className = 'c-Card';

  const baseClassNames = classNames(className, style[className], {
    [`${className}--success`]: success,
    [style[`${className}--success`]]: success,
    [`${className}--error`]: error,
    [style[`${className}--error`]]: error,
    [style[`${className}--text-center`]]: center,
    [style[`${className}--${type}`]]: !!type
  });

  return <div className={baseClassNames}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.any.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  center: PropTypes.bool,
  type: PropTypes.oneOf(cardTypesArr)
};

Card.defaultProps = {
  type: 'light'
};

export default Card;
