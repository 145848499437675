import React from 'react';

const LinkAwareText = ({ text = '' }) => {
  /*
  The way this work is by breaking the text into separate chunks, where the breaking
    point is the URL. The chunks are then mapped to a JSX element. If the chunk is a URL,
    it will be wrapped in an anchor tag, otherwise it will be put as a normal string.

    E.g.

    text = "Hello, I am a link https://www.google.com"

    It will be broken into
    [
        "Hello, I am a link ",
        "https://www.google.com"
    ]
   */
  let mappedText = [];

  // const oldRegex = /https?:\/\/[^\s]+/g;
  const linkRegex =
    /(?:https:?\/\/)?[A-Za-z0-9-_.]+\.[a-z]{2,12}(?:\/[^\s]*)?/gm;

  // text must be a string
  if (typeof text !== 'string') return '';

  const textParts = text.split(linkRegex) || [];
  const links = text.match(linkRegex);

  textParts.forEach((textPart, index) => {
    mappedText.push(textPart);
    if (links && links[index]) {
      const hasProtocol = links[index].startsWith('http');
      const link = hasProtocol ? links[index] : `https://${links[index]}`;
      mappedText.push(
        <a
          key={links[index]}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium text-npl-yellow-light-11">
          {links[index]}
        </a>
      );
    }
  });
  return mappedText;
};

export default LinkAwareText;
