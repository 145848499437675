import PropTypes from 'prop-types';
import classnames from 'classnames';
import { usePopper } from 'react-popper';
import { useState } from 'react';
/**
 * Headless dropdown component that only contains the logic to display or close the dropdown
 * @param renderField The rendering logic for the dropdown trigger, it exposes API of {isOpen}
 * @param renderOptions The rendering logic for the dropdown options, it exposes API of {closeDropdown}
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const HeadlessDropdown = ({
  renderField,
  renderOptions,
  className,
  placement
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement
    }
  );

  return (
    <div className={classnames('', className)}>
      <div
        role="button"
        tabIndex={0}
        onClick={isOpen ? closeDropdown : openDropdown}
        ref={setReferenceElement}
        className="cursor-pointer select-none">
        {renderField({
          isOpen,
          closeDropdown,
          openDropdown
        })}
      </div>
      {isOpen && (
        <>
          <div
            role="button"
            tabIndex={0}
            className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-opacity-0 cursor-default bg-white-default"
            onClick={closeDropdown}></div>
          <div
            className="absolute z-20 my-4 shadow-npl-styles-shadow-02 rounded-12"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}>
            {renderOptions({ isOpen, closeDropdown, openDropdown })}
          </div>
        </>
      )}
    </div>
  );
};

HeadlessDropdown.propTypes = {
  renderField: PropTypes.func.isRequired,
  renderOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string
};

HeadlessDropdown.defaultProps = {
  placement: 'bottom-start'
};

export default HeadlessDropdown;
