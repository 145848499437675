import config from '@/utility/config';

export const EVENT_TYPE = {
  UPCOMING: 'upcoming',
  PAST: 'past'
};

export const [TAB_UPCOMING_EVENTS, TAB_PAST_EVENTS] = ['upcoming', 'past'];

export const tabs = [
  {
    id: TAB_UPCOMING_EVENTS,
    label: 'Upcoming',
    testId: 'upcoming-events'
  },
  { id: TAB_PAST_EVENTS, label: 'Past', testId: 'past-events' }
];

export const [EVENT_STATUS_ACTIVE, EVENT_STATUS_CANCELLED] = [
  'Active',
  'Cancelled'
];

export const [EVENT_TYPE_LIVE, EVENT_TYPE_IN_PERSON] = [
  'live',
  'inPerson'
];

export const [EVENT_ACCESS_FREE, EVENT_ACCESS_PAID] = ['free', 'paid'];

export const emptyImageGraphic = {
  mobileImgProps: {
    src: `${config.imagesBasePath}/nasIO/portal/png/eventListGraphic.png`,
    layout: 'fixed',
    width: 280,
    height: 280
  }
};

export const defaultHostProfileImage =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/placeholder-guest-profile-picture.png';

export const IS_EVENT_WITH_MAGIC_REACH_NOTIFICATION_DISABLED =
  'isEventWithMagicReachNotificationDisabled';

export const DISCOUNT_ANNOUNCEMENT_EVENT_PAGE_MODAL_PROPS = {
  title: 'Create discount for paid events',
  description:
    'Click on the menu to create a new discount and get more sales!',
  imageProps: {
    alt: 'event-page',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/discount/events.png',
      width: 500,
      height: 250
    }
  }
};
