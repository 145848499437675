import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@/components/common/Icons/CloseIcon';

const Toast = ({
  isVisible,
  text,
  onClose,
  showCloseIcon,
  customContainerClass
}) => {
  const toastRef = useRef(null);

  //add animation
  useEffect(() => {
    if (toastRef.current) {
      const toastAnimation = toastRef.current.animate(
        [
          { transform: 'translate(-50%, 100%)', opacity: 0 },
          { transform: 'translate(-50%, 0)', opacity: 1 }
        ],
        {
          duration: 150,
          fill: 'forwards'
        }
      );

      if (isVisible) toastAnimation.play();
    }
  }, [isVisible]);

  return (
    isVisible && (
      <div
        ref={toastRef}
        className={`fixed flex items-center ${customContainerClass} justify-center p-16 opacity-0 left-2/4 -translate-x-2/4 bg-dark-netural10 rounded-8 z-999 bottom-48 `}>
        <p className="text-white-default">{text}</p>
        {showCloseIcon && (
          <div
            className="ml-16 cursor-pointer"
            onClick={onClose}
            role="button"
            tabIndex={0}>
            <CloseIcon strokeColor="#fff" />
          </div>
        )}
      </div>
    )
  );
};

Toast.defaultProps = {
  isVisible: false,
  text: '',
  onClose: () => {},
  showCloseIcon: false,
  customContainerClass: ''
};

Toast.propTypes = {
  isVisible: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  text: PropTypes.string,
  onClose: PropTypes.func
};

export default Toast;
