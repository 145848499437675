import React, { useEffect, useRef, useState } from 'react';

import LinkAwareText from '../LinkAwareText';
import PropTypes from 'prop-types';

const ClampText = ({
  text,
  line,
  customClassNames,
  ctaCustomClassNames,
  isDefaultExpanded,
  collapsedCtaText,
  expandedCtaText,
  onlyExpandOnce,
  hideCTA,
  disableLinkAware
}) => {
  const textRef = useRef(null);

  const [isCtaVisible, setIsCtaVisible] = useState(true);
  const [isExpandedUIState, setIsExpandedUIState] =
    useState(isDefaultExpanded);

  const hasExpandedOnce = onlyExpandOnce && isExpandedUIState;

  const toggleCollapsedState = () => setIsExpandedUIState((prev) => !prev);

  // check if clamping is needed or not on the first load
  useEffect(() => {
    if (textRef.current)
      if (
        textRef.current.offsetHeight === textRef.current.scrollHeight &&
        !isDefaultExpanded
      )
        setIsCtaVisible(false);
  }, [text, line, isDefaultExpanded]);

  return (
    <div className={`relative ${customClassNames}`}>
      <p
        ref={textRef}
        style={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isExpandedUIState ? 'unset' : `${line}`,
          wordBreak: 'break-word',
          whiteSpace: 'pre-line'
        }}>
        {disableLinkAware ? text : <LinkAwareText text={text} />}
      </p>

      {!hideCTA && !hasExpandedOnce && isCtaVisible && (
        <div
          role="button"
          tabIndex="0"
          className={`font-semibold text-npl-text-icon-on-light-surface-tertiary cursor-pointer bg-white-default w-fit ${
            isExpandedUIState
              ? 'ml-auto mt-16 text-right pl-0'
              : 'pl-8 absolute right-0 bottom-0'
          } ${ctaCustomClassNames}`}
          onClick={toggleCollapsedState}>
          {isExpandedUIState ? expandedCtaText : collapsedCtaText}
        </div>
      )}
    </div>
  );
};

ClampText.defaultProps = {
  isDefaultExpanded: false,
  text: '',
  line: 1,
  customClassNames: '',
  ctaCustomClassNames: '',
  collapsedCtaText: '...See more',
  expandedCtaText: 'Hide',
  onlyExpandOnce: false,
  hideCTA: false,
  disableLinkAware: false
};

ClampText.propsTypes = {
  isDefaultExpanded: PropTypes.bool,
  text: PropTypes.string,
  line: PropTypes.number,
  customClassNames: PropTypes.string,
  ctaCustomClassNames: PropTypes.string,
  collapsedCtaText: PropTypes.string,
  expandedCtaText: PropTypes.string,
  onlyExpandOnce: PropTypes.bool,
  hideCTA: PropTypes.bool,
  disableLinkAware: PropTypes.bool
};

export default React.memo(ClampText);
