import { showToast } from '@/components/common/ToastContainer';
import {
  checkDiscountCode,
  createDiscountCode,
  editDiscountCode,
  getDiscountInfo,
  getDiscounts
} from '@/services/discountService';
import { useCallback, useState } from 'react';

export const DISCOUNT_ENTITY_TYPE = {
  EVENT: 'EVENT',
  FOLDER: 'FOLDER',
  SUBSCRIPTION: 'SUBSCRIPTION'
};

// TODO: check for type before showing the discount value
export const DISCOUNT_VALUE_TYPE = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount'
};

const PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;

const useDiscount = ({ communityId }) => {
  const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NUMBER);
  const [discountList, setDiscountList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [isSingleDiscountLoading, setIsSingleDiscountLoading] =
    useState(false);
  const [isDiscountListLoading, setIsDiscountListLoading] =
    useState(false);
  const [isDiscountCreationLoading, setIsDiscountCreationLoading] =
    useState(false);
  const [isDiscountEditLoading, setIsDiscountEditLoading] =
    useState(false);
  const [isDiscountCheckLoading, setIsDiscountCheckLoading] =
    useState(false);

  const totalPages = Math.ceil(totalCount / PAGE_SIZE);
  const maxPageLength = pageNo * PAGE_SIZE;
  const hasNextPage = totalPages > pageNo;
  const hasPreviousPage = DEFAULT_PAGE_NUMBER < pageNo;

  const paginationText = `Showing ${(pageNo - 1) * PAGE_SIZE + 1}-${
    maxPageLength > totalCount ? totalCount : maxPageLength
  } of ${totalCount}`;

  const getDiscountList = useCallback(async () => {
    try {
      setIsDiscountListLoading(true);

      const params = {
        pageNo: pageNo,
        pageSize: PAGE_SIZE
      };

      const { data, error } = await getDiscounts({ params, communityId });

      if (error) throw new Error(error.error);

      setTotalCount(data?.data?.metadata?.total ?? 0);
      setDiscountList(data?.data?.discounts ?? []);
    } catch (e) {
      showToast({ nplVersion: 2, type: 'error', text: e.message });
    } finally {
      setIsDiscountListLoading(false);
    }
  }, [communityId, pageNo]);

  const getNextDiscountList = () => {
    if (hasNextPage) setPageNo((prev) => prev + 1);
  };

  const getPreviousDiscountList = () => {
    if (hasPreviousPage) setPageNo((prev) => prev - 1);
  };

  const createSingleDiscountCode = async ({ params }) => {
    try {
      setIsDiscountCreationLoading(true);

      const { data, error } = await createDiscountCode({
        communityId,
        params
      });

      if (error) throw new Error(error.error);

      return { error: null, data };
    } catch (e) {
      return { error: e.message };
    } finally {
      setIsDiscountCreationLoading(false);
    }
  };

  const getSingleDiscountInfo = async ({ communityId, code }) => {
    try {
      setIsSingleDiscountLoading(true);

      const { data, error } = await getDiscountInfo({
        communityId,
        code
      });

      if (error) throw new Error(error.error);

      return { error: null, data };
    } catch (e) {
      return { error: e.message };
    } finally {
      setIsSingleDiscountLoading(false);
    }
  };

  const editSingleDiscountCode = async ({
    params,
    discountId,
    onSuccess
  }) => {
    try {
      setIsDiscountEditLoading(true);

      const { error } = await editDiscountCode({
        communityId,
        discountId,
        params
      });

      if (error) throw new Error(error.error);

      onSuccess?.();
    } catch (e) {
      showToast({ nplVersion: 2, text: e.message, type: 'error' });
    } finally {
      setIsDiscountEditLoading(false);
    }
  };

  const checkIfDiscountCodeExists = async ({ params }) => {
    try {
      setIsDiscountCheckLoading(true);

      const { error } = await checkDiscountCode({
        communityId,
        params
      });

      if (error) throw new Error(error.error);

      return { error: null };
    } catch (e) {
      return { error: e.message };
    } finally {
      setIsDiscountCheckLoading(false);
    }
  };

  const updateDiscountList = (selectedDiscount) => {
    const tempDiscountList = [...discountList];

    const updatedDiscountList = tempDiscountList.map((discount) => {
      if (discount._id === selectedDiscount._id) return selectedDiscount;

      return discount;
    });

    setDiscountList(updatedDiscountList);
  };

  return {
    isDiscountCreationLoading,
    isDiscountCheckLoading,
    createSingleDiscountCode,
    checkIfDiscountCodeExists,
    editSingleDiscountCode,
    isDiscountEditLoading,

    discountList,
    paginationText,
    isDiscountListLoading,
    getSingleDiscountInfo,
    isSingleDiscountLoading,
    getDiscountList,
    getNextDiscountList,
    getPreviousDiscountList,
    hasNextPage,
    hasPreviousPage,
    updateDiscountList
  };
};

export default useDiscount;
