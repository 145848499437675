import config from '@/utility/config';
import {
  deviceType,
  isIOS,
  isMobile,
  isTablet
} from 'react-device-detect';
import {
  PAST_EVENTS_ENDPOINT,
  UPCOMING_EVENTS_ENDPOINT
} from '../community/communitiesManagementEventsService';

export const sharedRequestOptions = {
  withCredentials: true,
  headers: {
    device: deviceType,
    isios: isIOS,
    ismobile: isMobile,
    istablet: isTablet
  }
};

export const BASE_COMMUNITIES_URL = `${config.communityApiBasePath}/api/v1/communities`;

export const eventsURL = (communityId, eventsType) =>
  `${BASE_COMMUNITIES_URL}/${communityId}/events/${eventsType}`;

export const upcomingEventsURL = (communityId) =>
  eventsURL(communityId, UPCOMING_EVENTS_ENDPOINT);
export const pastEventsURL = (communityId) =>
  eventsURL(communityId, PAST_EVENTS_ENDPOINT);
