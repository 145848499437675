import Modal from '@/components/common/Modal';
import React, { useEffect, useState } from 'react';
import IconByName from '@/components/common/IconsV2/IconByName';
import Toast from '@/pages/portal/components/Toast';
import { copyTextToClipboard } from '@/pages/portal/utils/events';
import ToolTip from '@/components/common/ToolTip';
import { openInNewTab } from '@/components/common/Editor/utils/openInNewTab';
import PopOverModal from '../PopOverModal';

const ShareLink = ({ icon, href, title, ...rest }) => {
  return (
    <ToolTip text={title} customClassName="w-[auto] text-center">
      <a
        href={href}
        className="flex items-center justify-center p-12 border rounded-full border-neutral-80"
        target="_blank"
        rel="noreferrer noopener"
        {...rest}>
        {icon}
      </a>
    </ToolTip>
  );
};
const ShareModal = ({
  open,
  onClose,
  title,
  shareText,
  shareUrl,
  label,
  onShareClick,
  isMobileView
}) => {
  const encodedShareText = encodeURIComponent(shareText);
  const encodedShareUrl = encodeURIComponent(shareUrl);
  const [nativeShareSupported, setNativeShareSupported] = useState(false);
  useEffect(() => {
    setNativeShareSupported(navigator && !!navigator.share);
  }, []);

  const handleShareClick = (params) => {
    if (onShareClick) onShareClick(params);
  };

  const onNativeShareClick = () => {
    if (nativeShareSupported) {
      navigator.share({
        title: shareText,
        url: shareUrl
      });
    }

    handleShareClick({ mode: 'native-share' });
  };

  const [toastOpen, setToastOpen] = useState(false);

  const onCopyShareUrl = () => {
    copyTextToClipboard({ text: shareUrl });
    setToastOpen(true);
    setTimeout(() => {
      setToastOpen(false);
    }, 3000);
    handleShareClick({ mode: 'copy-link' });
  };

  const renderContent = () => {
    return (
      <>
        <h3 className="text-24 text-neutral-10 font-medium tracking-[-0.019em]">
          {title}
        </h3>
        <div className="flex gap-12 mt-24">
          {nativeShareSupported && (
            <ShareLink
              title="Share"
              onClick={onNativeShareClick}
              icon={<IconByName name="share" />}
            />
          )}
          <ShareLink
            title="WhatsApp"
            href={`https://api.whatsapp.com/send?text=${encodedShareText}+${encodedShareUrl}`}
            icon={<IconByName name="whatsapp" />}
            onClick={() => handleShareClick({ mode: 'whatsapp' })}
          />
          <ShareLink
            title="Twitter"
            href={`https://twitter.com/intent/tweet?text=${encodedShareText}&url=${encodedShareUrl}`}
            icon={<IconByName name="twitter" />}
            onClick={() => handleShareClick({ mode: 'twitter' })}
          />
          <ShareLink
            title="LinkedIn"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareUrl}`}
            icon={<IconByName name="linkedin" />}
            onClick={() => handleShareClick({ mode: 'linkedin' })}
          />
          <ShareLink
            title="Facebook"
            href={`https://www.facebook.com/sharer.php?u=${encodedShareUrl}`}
            icon={<IconByName name="facebook" />}
            onClick={() => handleShareClick({ mode: 'facebook' })}
          />
        </div>
        <div className="mt-24">
          <div className="mb-8 font-medium text-16 text-neutral-10">
            {label}
          </div>
          <div className="flex items-center justify-center gap-16 p-16 bg-neutral-97 rounded-12">
            <input
              type="url"
              readOnly
              onClick={() => openInNewTab(shareUrl)}
              className="flex-grow flex-shrink-0 bg-transparent outline-none text-neutral-50"
              value={shareUrl}
            />
            <ToolTip
              text="Copy link"
              customClassName="w-[90px] text-center">
              <IconByName
                name="copy-content"
                fill="#191C1E"
                customClassNames="cursor-pointer"
                onClick={onCopyShareUrl}
              />
            </ToolTip>
          </div>
        </div>
        <Toast
          customContainerClass=""
          text="Copied!"
          isVisible={toastOpen}
          onClose={() => setToastOpen(false)}
          showCloseIcon={false}
        />
      </>
    );
  };
  return (
    <>
      {isMobileView ? (
        <Modal
          open={open}
          onClose={onClose}
          size="md"
          customCloseIconClass="!top-20 !right-12"
          customContainerClass={`font-poppins !rounded-12`}>
          {renderContent()}
        </Modal>
      ) : (
        <PopOverModal open={open} onClose={onClose}>
          {renderContent()}
        </PopOverModal>
      )}
    </>
  );
};

export default ShareModal;
