import Auth from '@/modules/Auth';
import adminProtectedAxiosRequest from '@/services/helpers/adminProtectedAxiosRequest';
import axios from 'axios';
import { getAxiosError } from '../helpers';
import {
  BASE_COMMUNITIES_URL,
  eventsURL,
  sharedRequestOptions
} from '../helpers/urls';

export const UPCOMING_EVENTS_ENDPOINT = 'upcoming';
export const PAST_EVENTS_ENDPOINT = 'past';

/**
 *
 * @param {string} communityId
 * @param {'past'|'upcoming'} eventsType
 * @returns {Promise<{data: Array<Event>, error: string}>}
 */
export const getEventsData = async (communityId, eventsType, params) => {
  try {
    return await adminProtectedAxiosRequest.get(
      eventsURL(communityId, eventsType),
      { ...sharedRequestOptions, params }
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

/**
 *
 * @param {Object} event
 * @returns {Promise<Object>}
 */
export const createEvent = async (event, communityId) => {
  try {
    const res = await adminProtectedAxiosRequest.post(
      `${BASE_COMMUNITIES_URL}/${communityId}/events`,
      event
    );
    return { data: res.data?.data || res.data, error: null };
  } catch (error) {
    return getAxiosError(error);
  }
};
/**
 *
 * @param {string} eventId
 * @param {object} event
 * @returns {Promise<object>}
 */
export const updateEvent = async (eventId, event, communityId) => {
  try {
    return await adminProtectedAxiosRequest.put(
      `${BASE_COMMUNITIES_URL}/${communityId}/events/${eventId}`,
      event
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

/**
 *
 * @param {string} eventId
 * @returns
 */
export const deleteEvent = async (eventId) => {
  try {
    return await adminProtectedAxiosRequest.delete(
      `${BASE_COMMUNITIES_URL}/event/${eventId}`
    );
  } catch (error) {
    return getAxiosError(error);
  }
};

export const rsvpEvent = async (timezone, communityId, token) => {
  try {
    const accessToken = Auth.getUserToken() || token;
    const path = `${BASE_COMMUNITIES_URL}/event/${communityId}/register?timezoneId=${timezone}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    const res = await axios(path, options);
    const data = await res.data;

    return data;
  } catch (e) {
    return getAxiosError(e);
  }
};

export const downloadEventRsvpList = async (communityId, eventId) => {
  try {
    return await adminProtectedAxiosRequest.get(
      `${BASE_COMMUNITIES_URL}/${communityId}/event/${eventId}/rsvp-list`
    );
  } catch (error) {
    return getAxiosError(error);
  }
};
