import PropTypes from 'prop-types';
import style from './LoadingCircle.module.scss';

const className = 'c-LoadingCircle';

const LoadingCircle = ({
  withFullScreen,
  text,
  customSpinnerClassName
}) => {
  const fullScreenClassName = withFullScreen ? style['full-screen'] : '';

  return (
    <div className={fullScreenClassName}>
      <div
        className={`c-LoadingCircle ${style[className]} w-[5em] h-[5em] after:w-[5em] after:h-[5em] rounded-[50%] mx-60 ${customSpinnerClassName}`}
      />
      {text && (
        <p className="font-medium text-center text-heading-sm translate-y-[-25px]">
          {text}
        </p>
      )}
    </div>
  );
};

LoadingCircle.propTypes = {
  withFullScreen: PropTypes.bool,
  text: PropTypes.string
};

LoadingCircle.defaultProps = {
  customSpinnerClassName: 'my-[60px] w-5em h-5em'
};
export default LoadingCircle;
