import {
  CHAT_INTEGRATION_FEATURE_PAGE_ROUTE,
  CM_PORTAL_EVENTS_ROUTE,
  CM_PORTAL_LIBRARY_PAGE_ROUTE,
  CM_PORTAL_MAGIC_REACH_PAGE_ROUTE,
  CM_PORTAL_MONEY_PAGE_ROUTE,
  CM_PORTAL_PUBLIC_PAGE_ROUTE,
  CM_PORTAL_SETTINGS_PAGE_ROUTE,
  CONTENT_LIB_FEATURE_PAGE_ROUTE,
  EVENTS_FEATURE_PAGE_ROUTE,
  MAGIC_REACH_FEATURE_PAGE_ROUTE,
  MONETIZATION_FEATURE_PAGE_ROUTE,
  PUBLIC_PAGE_FEATURE_PAGE_ROUTE
} from './routesHelper';

export const SHOW_START_COMMUNITY_MODAL = 'SHOW_START_COMMUNITY_MODAL';
export const DEMO_TOGGLE_TO_MEMBER_VIEW = 'DEMO_TOGGLE_TO_MEMBER_VIEW';
export const DEMO_TOGGLE_TO_CM_VIEW = 'DEMO_TOGGLE_TO_CM_VIEW';
export const DEMO_CLICK_CMP_LOGOUT = 'DEMO_CLICK_CMP_LOGOUT';
export const DEMO_CLICK_MP_LOGOUT = 'DEMO_CLICK_MP_LOGOUT';

export function isSessionInSandboxEnv() {
  try {
    const isInDemoEnv =
      window.parent.location.pathname === '/product-demo';
    return isInDemoEnv;
  } catch (e) {
    return false;
  }
}

export function showStartOnboardingModalInDemo(locationOfCta) {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: SHOW_START_COMMUNITY_MODAL,
      locationOfCta
    };
    window.parent.postMessage(message, targetOrigin);
  }
}

export function switchDemoToCmView() {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: DEMO_TOGGLE_TO_CM_VIEW
    };
    window.parent.postMessage(message, targetOrigin);
  }
}

export function switchDemoToMemberView() {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: DEMO_TOGGLE_TO_MEMBER_VIEW
    };
    window.parent.postMessage(message, targetOrigin);
  }
}

export function handleLogoutClickFromProductDemo() {
  if (isSessionInSandboxEnv()) {
    const targetOrigin = '*';
    const message = {
      fromDemo: true,
      type: DEMO_CLICK_CMP_LOGOUT
    };
    window.parent.postMessage(message, targetOrigin);
  }
}

const FEATURE_PATH_NAME_CMROUTE_MAP = [
  {
    featureName: 'EVENTS',
    featurePath: EVENTS_FEATURE_PAGE_ROUTE,
    cmPortalRoute: CM_PORTAL_EVENTS_ROUTE
  },
  {
    featureName: 'MONETIZATION',
    featurePath: MONETIZATION_FEATURE_PAGE_ROUTE,
    cmPortalRoute: CM_PORTAL_MONEY_PAGE_ROUTE
  },
  {
    featureName: 'MAGIC_REACH',
    featurePath: MAGIC_REACH_FEATURE_PAGE_ROUTE,
    cmPortalRoute: CM_PORTAL_MAGIC_REACH_PAGE_ROUTE
  },
  {
    featureName: 'LIBRARY',
    featurePath: CONTENT_LIB_FEATURE_PAGE_ROUTE,
    cmPortalRoute: CM_PORTAL_LIBRARY_PAGE_ROUTE
  },
  {
    featureName: 'CHAT_INTEGRATIONS',
    featurePath: CHAT_INTEGRATION_FEATURE_PAGE_ROUTE,
    cmPortalRoute: CM_PORTAL_SETTINGS_PAGE_ROUTE
  },
  {
    featureName: 'PUBLIC_PAGE',
    featurePath: PUBLIC_PAGE_FEATURE_PAGE_ROUTE,
    cmPortalRoute: CM_PORTAL_PUBLIC_PAGE_ROUTE
  }
];

export function getFeatureNameByPagePath(path) {
  const mapData = FEATURE_PATH_NAME_CMROUTE_MAP.find(
    (mapData) => mapData.featurePath === path
  );
  return mapData?.featureName || '';
}

export function getCmPortalRouteByFeatureName(featureName) {
  const mapData = FEATURE_PATH_NAME_CMROUTE_MAP.find(
    (mapData) => mapData.featureName === featureName
  );
  return mapData?.cmPortalRoute || '';
}
